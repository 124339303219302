:root{
	--green :  #2f8d46;
}

footer{
	/* margin-top: 50px; */
	background: #1D202F;
	
	justify-content: center;
	color: var(--primary-white);
	/* border-top-left-radius: 30px;
	border-top-right-radius: 30px; */
}

.top-panel{
	padding-top: 50px;
	display: flex;
	justify-content: center;
}
.container{
	margin: auto;
	padding-top: 40px;
	display: flex;
  	flex-direction: row;
	justify-content: center;
}
.footer-content{
	width: 33.3%;
}
h3{
	font-size: 28px;
	margin-bottom: 15px;
	text-align: center;
}
.footer-content p{

	margin: auto;
	padding: 7px;
}
.footer-content ul{
	text-align: center;
}
.list{
	padding: 0;
}
.list li{
	width: auto;
	text-align: center;
	list-style-type:none;
	padding: 7px;
	position: relative;
}
.list li::before{
	content: '';
	position: absolute;
	transform: translate(-50%,-50%);
	left: 50%;
	top: 100%;
	width: 0;
	height: 2px;
	background: var(--primary-green);
	transition-duration: .5s;
}
.list li:hover::before{
	width: 70px;
}
.social-icons{
	text-align: center;
	padding: 0;
}
.social-icons li{
	display: inline-block;
	text-align: center;
	padding: 5px;
}
.social-icons i{
	color: white;
	font-size: 25px;
}

.footer-anchor{
	text-decoration: none;
	color: var(--primary-white);
}
.footer-anchor:hover{
	color: var(--green);
}
.social-icons i:hover{
	color: var(--green);
}
.bottom-bar{
	background: var(--green);
	text-align: center;
	
	margin-top: 30px;
}
.bottom-bar p{
	color: #f3f3f3;
	margin: 0;
	font-size: 16px;
	padding: 7px;
}

.footer-logo{
	width: 60px;
	height: 60px;
	padding-left: 10px;
    border-radius: 50%;
    background-image: url('../../assets/gfg-student-chapter-logo.png');
    background-size: cover;

}
@media only screen and (max-width: 800px) { 
	.container {
		justify-content: center;
		flex-direction: column;
	}

	.footer-content{
		text-align: center;
		width: 100%;
		margin-top: 20px;
	}
  }