
.connect{
  display: flex;
  align-items: center;
  width: 100%;
  /* justify-content: center; */
  justify-content: space-around;
  height: 80%;
  background-color: #1D202F;
  margin-bottom: 200px;
}
.tet
{
  /* width: 200px; */
  background-color: #1D202F;
}
.space{
  width: 320px;
  height: 100px;

}
.spacename{
  background-color: #2f8d46;
}
#map
{
  margin-left: 500px;
}
.GM{
  border-radius: 5%;
  /* margin-left: 500px; */
}
.links{
  /* margin-left: 100px; */
  margin-top: 50px;
}
.i{
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.bg{
  align-content: center;

  display: flex;
  flex-direction: row;
  margin-left: 50px;
  font-size: larger;
  font-weight: 500;
  justify-content: space-around;
}
.icon
{
  width: 40px;
  height: 40px;
}
.im
{
  width: 50%;
  background-color: #2f8d46;
  height: 150%;
  margin-left: 110px;
  margin-bottom: 40px;
  justify-content: space-around;
  border-radius: 5%;
}
.ic
{
  /* background-color: #2f8d46; */
  justify-content: space-around;
  display: flex;
  align-content: center;

}
button{
  font-size: large;
  
}
#ln{
  /* border-width:3px; */
  border-bottom: 3px;
  border-style:solid;
  margin: 0 20px;
}
.f1
{
  font-weight: 700;
  font-size: larger;
}
#ds
{
  border-width: 3px;
  border-style:solid;
  margin: 10px;
  padding: 12px;
  height: 25%; 
  border-radius: 5%;
  text-align: start;
  vertical-align: top;
}
#bt{
  background-color: #2f8d46;
  width: 30%;
  height: 7%;
  justify-content: space-between;
  align-content: center;
  border-radius: 4%;
  margin-bottom: 10px;
  margin-left: 32%;
}