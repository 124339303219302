
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.caption {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}
