/* About.css */
/* Common styles for all modes */
:root {
    --nav-height: 80px;
    --primary-green: #2f8d46;
    --primary-black: #080808;
    --footer-bg: #1D202F;
    --primary-white: #FBFCFA;
    --font-roboto: Roboto;
    --font-size-xl: 20px;
    --background: #fff;
    --body-font-family: "Lexend-Regular", Helvetica;
    --body-font-size: 20px;
    --body-font-style: normal;
    --body-font-weight: 400;
    --body-letter-spacing: 0px;
    --body-line-height: 110.00000238418579%;
    --h1-font-family: "Lexend-Bold", Helvetica;
    --h1-font-size: 70px;
    --h1-font-style: normal;
    --h1-font-weight: 700;
    --h1-letter-spacing: 0px;
    --h1-line-height: 110px;
  }
  
  
  .leftpanel {
    text-align: center;
  }
  
  .overlap-group {
    position: relative;
    top: -1px;
  }
  
  .ham1 {
    color: #000000;
  
    font-family: var(--h1-font-family);
    font-size: 70px;
    font-style: var(--h1-font-style);
    font-weight: var(--h1-font-weight);
    position: relative;
    text-shadow: 0px 4px 4px #00000040;
  }
  
  .text-wrapper-5 {
    color: #2a2a2a;
  
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    font-style: var(--body-font-style);
    font-weight: var(--body-font-weight);
    position: relative;
  }
  
  .imgcontainer {
    /* width: 1120px; */
    /* height: 300px; */
    flex-shrink: 0;
    border-radius: 21px;
    background: #FFF;
  }
  
  .ellipse1 {
    width: 335px;
    height: 267px;
    flex-shrink: 0;
    fill: #B25656;
    filter: blur(118.5px);
  }
  
  
  .our-domains {
    color: #000000;
    font-family: var(--h1-font-family);
    font-size: 70px;
    font-style: var(--h1-font-style);
    font-weight: var(--h1-font-weight);
    position: relative;
  }
  
  .text-a1 {
    /* width: 654px; */
    /* height: 114px; */
    flex-shrink: 0;
  }
  
  .tp1 {
    color: #000;
    font-family: Lexend;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .tp2 {
    color: #000;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 30px */
    letter-spacing: 0.4px;
    text-transform: capitalize;
  }
  
  .programming-code {
    width: 300px;
    height: 299px;
    flex-shrink: 0;
    margin: 0 auto;
  }
  
  
  @keyframes spin {
    0% {
      opacity: 0;
    }
  
    33.33% {
      opacity: 1;
    }
  
    66.66% {
      opacity: 0;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes rotateBarrel {
    0% {
      transform: translateY(0) rotateX(0);
      opacity: 1;
    }
  
    25% {
      transform: translateY(-50px) rotateX(180deg);
      opacity: 0.75;
    }
  
    75% {
      transform: translateY(-150px) rotateX(360deg);
      opacity: 0.25;
    }
  
    100% {
      transform: translateY(-200px) rotateX(540deg);
      opacity: 0;
    }
  }
  
  @keyframes spinner {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spinShaft {
    0% {
      transform: rotateX(0);
    }
  
    100% {
      transform: rotateX(360deg);
    }
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
  
    25% {
      opacity: 1;
    }
  
    75% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  .anigeek {
    display: inline-block;
    color: var(--primary-green);
    margin: 0;
    font-family: var(--h1-font-family);
    font-size: 3rem;
    /* Update the font-size here */
    font-style: var(--h1-font-style);
    font-weight: var(--h1-font-weight);
    position: relative;
    text-shadow: 0px 4px 4px #00000040;
    animation: spin 4s linear infinite alternate;
  }
  
  .rightpanel {
    flex: 1;
    text-align: center;
    width: 60%;
  }
  
  .software-code {
    max-width: 100%;
    height: auto;
  }
  
  
  
  /* Styles for phone mode (screen width < 768px) */
  @media (max-width: 767px) {
  
  
    .leftpanel,
    .rightpanel {
      width: 100%;
    }
  
    .ham1 {
      font-size: 24px;
    }
  
    .text-wrapper-5 {
      font-size: 16px;
    }
  
    .software-code {
      max-width: 100%;
      /* Adjust the image size for phone mode */
    }
  
    .anigeek {
      font-size: 24px;
    }
  }
  
  /* Styles for tablet mode (screen width >= 768px and <= 1199px) */
  @media (min-width: 768px) and (max-width: 1199px) {
  
    .ham1 {
      font-size: 36px;
    }
  
    .text-wrapper-5 {
      font-size: 20px;
    }
  
    .leftpanel,
    .rightpanel {
      width: 100%;
    }
  
    .software-code {
      max-width: 100%;
      /* Adjust the image size for tablet mode */
    }
  
    .anigeek {
      font-size: 2.5rem;
    }
  }
  
  /* Styles for desktop mode (screen width >= 1200px) */
  @media (min-width: 1200px) {
  
    .leftpanel {
      width: 60%;
      text-align: left;
    }
  
    .rightpanel {
      width: 40%;
      text-align: right;
    }
  
    .ham1 {
      font-size: 48px;
    }
  
    .text-wrapper-5 {
      font-size: 24px;
    }
  
    .anigeek {
      font-size: 3rem;
    }
  
    .software-code {
      max-width: 100%;
      /* Reset the image size for desktop mode */
    }
  }
  
  
  
  
  
  
  
  /*CSS for Tablet*/
  @media screen and (max-width: 1023px) {
    .imgcontainer {
      flex-direction: column;
      text-align: center;
    }
  
    .text-area,
    .about-logo-section {
      padding: 1rem;
    }
  
    .bgrad,
    .bgradi {
      display: none;
    }
  }
  
  
  @media screen and (max-width: 767px) {
    .imgcontainer {
      flex-direction: column;
      text-align: center;
    }
  
    .text-area,
    .about-logo-section {
      padding: 1rem;
    }
  
    .tp1 {
      font-size: 1.5rem;
    }
  
    .tp2 {
      font-size: 1rem;
    }
  }