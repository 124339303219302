:root {
    --nav-height: 100px;
    --primary-green: #2f8d46;
    --primary-black: #080808;
    --footer-bg: #1D202F;
    --primary-white: #FBFCFA;
    --font-roboto: Roboto;
    --font-size-xl: 25px;
    --background: #fff;
}

/* ---------------------------------- MAIN NAVBAR ----------------------------------------- */
.navbar {
    flex-shrink: 0;
    display: flex;
    height: var(--nav-height);
    align-items: center;
    border-radius: 0px 0px 16px 16px;
    background: #fff;
    color: var(--primary-black);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25),
        0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    position: relative;
}

/* --------------LEFT PANEL OF NAVBAR ---------------------- */
.nav-left {
    display: flex;
    align-items: center;
}

.nav-logo {
    padding-left: 10px;
    border-radius: 50%;
    height: var(--nav-height);
    width: var(--nav-height);
    background-image: url('../../assets/gfg-student-chapter-logo.png');
    background-size: cover;
}

.nav-logo-txt {
    font-weight: 700;
}
/* ---------- Right Panel of NAvbar ------------ */

.nav-right {
    display: flex;
    margin: 10px;
    align-items: center;
}

.tabs {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.tabs li {
    display: inline;
    margin-left: 20px;
    margin-right: 20px;
}

.nav-item,
a {
    color: var(--primary-black);
    text-decoration: none;
    font-family: "Roboto-ExtraBold", Helvetica;
    font-size: medium;
    font-weight: 700;
}

.nav-item,
a:hover {
    color: var(--primary-green);
}

.signin-button {
    background-color: var(--primary-black);
    color: white;
    border: none;
    padding: 10px 20px;
    font-family: "Roboto-ExtraBold", Helvetica;
    font-size: medium;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: background-color 0.3s;
}

.signin-button:hover {
    background-color: #333;
    color: var(--primary-green);
}

/* -------------- HAMBURGER MENU --------------- */
.toggle-checkbox {
    display: none;
}

.toggle-label {
    cursor: pointer;
    display: none;
    user-select: none;
    order: 2;
    margin-right: 13px;
}

.toggle-icon {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--primary-black);
    border-radius: 3px;
    transition: background-color 0.3s;
}

.toggle-icon:before,
.toggle-icon:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 3px;
    background-color: var(--primary-black);
    border-radius: 3px;
    transition: transform 0.3s, background-color 0.3s;
}

.toggle-icon:before {
    transform: translateY(-8px);
}

.toggle-icon:after {
    transform: translateY(8px);
}


/* Dropdown styles */
.dropdown-drawer.isOpen {
    /* Keep the drawer open when it is in the isOpen state */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: var(--nav-height);
    left: 0;
    width: 100%;
    background-color: var(--background);
    padding: 10px 0;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25),
        0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
    animation: slide-down 0.3s ease-in-out;
}

/* Dropup styles */
.dropdown-drawer:not(.isOpen) {
    /* Hide the drawer when it is not in the isOpen state */
    display: none;
    opacity: 0;
    transform: translateY(-10px);
    animation: slide-up 0.3s ease-in-out;
}

@keyframes slide-up {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-10px);
        opacity: 0;
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.dropdown-drawer ul{
    list-style-type: none;
}
.dropdown-drawer li {
    text-align: center;
    margin-bottom: 10px;
    list-style-type: none;
}

.dropdown-drawer a {
    color: var(--primary-black);
    text-decoration: none;
    font-family: "Roboto-ExtraBold", Helvetica;
    font-size: large;
    font-weight: 700;
}

.dropdown-drawer a:hover {
    color: var(--primary-green);
}

@media (max-width: 1024px) { /* Updated media query breakpoint for tablet mode */
    .toggle-label {
        display: inline-block;
        padding: 5px;
        order: 1;
        margin-left: 10px;
    }

    .nav-right {
        display: none;
    }

    .signin-button {
        display: none;
    }
}

@media (max-width: 768px) {
    .toggle-checkbox:checked ~ .dropdown-drawer {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: var(--nav-height);
        left: 0;
        width: 100%;
        background-color: var(--background);
        padding: 10px 0;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 1;
        animation: slide-down 0.3s ease-in-out;
    }

    .toggle-checkbox:checked ~ .dropdown-drawer li {
        margin: 10px;
    }

    .toggle-checkbox:checked ~ .dropdown-drawer a {
        font-size: var(--font-size-xl);
    }

    .toggle-checkbox:checked ~ .toggle-label .toggle-icon {
        background-color: transparent;
    }

    .toggle-checkbox:checked ~ .toggle-label .toggle-icon:before {
        transform: translateY(0) rotate(45deg);
    }

    .toggle-checkbox:checked ~ .toggle-label .toggle-icon:after {
        transform: translateY(0) rotate(-45deg);
    }
}


/* Add the following CSS to toggle the display of the drawer based on the checkbox status */
@media (max-width: 1024px) {
    .toggle-checkbox:checked ~ .dropdown-drawer {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: var(--nav-height);
        left: 0;
        width: 100%;
        background-color: var(--background);
        padding: 10px 0;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 1;
        animation: slide-down 0.3s ease-in-out;
    }

    .toggle-checkbox:checked ~ .dropdown-drawer li {
        margin: 10px;
    }

    .toggle-checkbox:checked ~ .dropdown-drawer a {
        font-size: var(--font-size-xl);
    }

    .toggle-checkbox:checked ~ .toggle-label .toggle-icon {
        background-color: transparent;
    }

    .toggle-checkbox:checked ~ .toggle-label .toggle-icon:before {
        transform: translateY(0) rotate(45deg);
    }

    .toggle-checkbox:checked ~ .toggle-label .toggle-icon:after {
        transform: translateY(0) rotate(-45deg);
    }
}