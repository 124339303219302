@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --primary-font : 'Lexend';
  --primary-white : #FBFCFA;
}

*{
  margin: 0;
  border: border-box;
    
  font-family: var(--primary-font), BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

body { 
  max-width: 100%;
  overflow-x: hidden;
}