
:root{
--gfg-green: #2f8d46;

}

.tab{
    font-weight: 600;
}
.active-tab {
    color: var(--gfg-green);
    text-underline-offset: 20px;
  }